<template>
  <div>
    <el-tabs v-model="params.label_type" type="card" class="elTabBox" @tab-click="search('tab')">
      <el-tab-pane label="活动标签" name="1"></el-tab-pane>
      <el-tab-pane label="能力标签" name="2"></el-tab-pane>
    </el-tabs>
    <en-table-layout :tableData="tableData.data" :loading="loading" :tips="true">
      <!-- 搜索条件 -->
      <div slot="tips" class="TitleBox">
        <div class="conditions">
          <el-button
            @click="openDialog('new')"
            type="primary"
            class="btn-default"
            style="margin-right:40px;"
            size="mini"
          >新建</el-button>
        </div>
        <div style="width:270px;">
          <div class="conditions">
            <el-input v-model="params.label_name" placeholder="请输入标签名称" class="ipt-default" size="mini"></el-input>
            <el-button @click="search" type="primary" class="btn-default" size="mini" style="margin-left:20px;">搜索</el-button>
          </div>
        </div>
      </div>
      <template slot="table-columns">
        <!-- 编号 -->
        <el-table-column prop="id" label="编号">
          <template slot-scope="scope">
            <div>T{{scope.row.id}}</div>
          </template>
        </el-table-column>

        <!-- 标签名称 --->
        <el-table-column prop="label_name" label="标签名称" />
        <!--创建时间-->
        <el-table-column prop="create_date" :formatter="MixinUnixToDate" label="创建时间" />
        <!--操作-->
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <div class="buttonBox">
              <el-button
                @click="openDialog('edit',scope.row)"
                type="text"
                size="mini"
                style="margin-bottom:5px;"
              >编辑</el-button>
              <el-button
                @click="openDialog('del',scope.row)"
                type="text"
                size="mini"
                style="margin-bottom:5px;margin-left:0;color:#fe5558;"
              >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="tableData.data"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
    <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="dialogContent" v-if="dialogTitle==='删除标签'">{{dialogContent}}</div>
      <div class="tagBox" v-if="dialogTitle!=='删除标签'">
        <div>请输入标签名称：</div>
        <el-input v-model="tagParams.label_name" placeholder="请输入标签名称" class="ipt-default"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_activityTag from "@/api/activityTag";

export default {
  name: "activityList",
  data() {
    return {
      //  列表loading状态
      loading: false,

      // 列表参数
      params: {
        page_no: 1,
        page_size: 10,
        label_name: "",
        //  选项卡状态
        label_type: "1",
      },
      //  列表数据
      tableData: [
        {
          n1: "T001",
          n2: "职业体验",
          n3: Date.parse(new Date()) / 1000,
          id: "1",
        },
      ],
      // 弹框显示内容
      dialogTitle: "",
      dialogContent: "",
      centerDialogVisible: false,

      // 新建标签名称
      tagParams: {
        label_type: "",
        label_name: "",
      },

      // 选中的id
      chooseId: "",
    };
  },
  mounted() {
    this.POST_activeLabelShopPage();
  },
  activated() {
    this.POST_activeLabelShopPage();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.POST_activeLabelShopPage();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.POST_activeLabelShopPage();
    },

    /** 获取标签列表 */
    POST_activeLabelShopPage() {
      API_activityTag.activeLabelShopPage(this.params).then((res) => {
        this.tableData = res;
        console.log(res);
      });
    },

    /** 搜索 */
    search(type) {
      if (type === "tab") {
        this.params.label_name = "";
      }
      this.params.page_no = 1;
      this.params.page_size = 10;
      this.POST_activeLabelShopPage();
    },

    /** 打开弹窗 */
    openDialog(type, row) {
      this.centerDialogVisible = true;
      if (type === "new") {
        this.dialogTitle = "新建标签";
        this.tagParams.label_type = "";
        this.tagParams.label_name = "";
      } else if (type === "edit") {
        this.dialogTitle = "编辑标签";
        this.chooseId = row.id;
        this.tagParams.label_name = row.label_name;
      } else if (type === "del") {
        this.dialogTitle = "删除标签";
        this.dialogContent = "删除标签将无法找回，确认删除吗？";
        this.chooseId = row.id;
      }
    },

    /** 弹窗点击确定 */
    ok() {
      if (this.dialogTitle === "新建标签") {
        if (this.tagParams.label_name === "") {
          this.$message.console.error("标签名不能为空！");
          return false;
        }
        this.tagParams.label_type = this.params.label_type;
        // 添加活动标签接口
        API_activityTag.activeLabelAdd(this.tagParams).then((res) => {
          this.centerDialogVisible = false;
          this.$message.success("新建标签成功");
          this.tagParams.label_type = "";
          this.tagParams.label_name = "";
          this.POST_activeLabelShopPage();
        });
      } else if (this.dialogTitle === "编辑标签") {
        if (this.tagParams.label_name === "") {
          this.$message.error("标签名不能为空！");
          return false;
        }
        var params = {};
        params.id = this.chooseId;
        params.label_name = this.tagParams.label_name;
        API_activityTag.activeLabelEdit(params).then((res) => {
          this.centerDialogVisible = false;
          this.$message.success("编辑标签成功");
          this.tagParams.label_name = "";
          this.chooseId = "";
          this.POST_activeLabelShopPage();
        });
      } else if (this.dialogTitle === "删除标签") {
        API_activityTag.activeLabelDel(this.chooseId).then((res) => {
          this.centerDialogVisible = false;
          this.$message.success("删除标签成功");
          this.chooseId = "";
          this.POST_activeLabelShopPage();
        });
      }
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
/deep/ .el-table td:not(.is-left) {
  text-align: center;
}

.TitleBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .conditions {
    display: flex;
    align-items: center;
    .choose-machine {
      width: 60%;
    }
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogContent {
  font-size: 16px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}

.tagBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  div {
    width: 120px;
  }
}

/deep/ .toolbar {
  padding: 0 !important;
}

.elTabBox {
  /deep/ .el-tabs__item {
    background: #fff;
  }
  /deep/ .is-active {
    background: #008080;
    color: #fff;
  }
  /deep/ #tab-first {
    border-radius: 4px 0 0 4px;
  }
  /deep/ #tab-second {
    border-radius: 0 4px 4px 0;
  }
}
</style>
